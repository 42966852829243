import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Api from "./Api";
import { Button, TextField, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.css";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
});

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const enforceCharacterLimit = (quill, limit) => {
  quill.on("text-change", function (delta, old, source) {
    if (quill.getLength() > limit + 1) {
      quill.deleteText(limit, quill.getLength());
    }
  });
};

function UpdatePersonalInfo() {
  const [personalInfo, setPersonalInfo] = useState({});
  const [code, setCode] = useState("");
  const [notes, setNotes] = useState("");

  const quillRef = useRef(null);
  const maxChars = 250;
  const remainingChars = maxChars - notes.length;

  const query = useQuery();
  const specialCodeFromURL = query.get("code");

  useEffect(() => {
    if (specialCodeFromURL) {
      setCode(specialCodeFromURL);
      fetchUserInfo(specialCodeFromURL);
    }
  }, [specialCodeFromURL]);

  useEffect(() => {
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor();
      enforceCharacterLimit(quillInstance, 250);
    }
  }, [quillRef]);

  const fetchUserInfo = async (code) => {
    try {
      const response = await Api.get(`/get-info-by-code/${code}`);
      const personalInfo = response.data;
      setPersonalInfo({
        name: personalInfo.name || "",
        email: personalInfo.email || "",
      });
      setNotes(personalInfo.notes || "");
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNotesChange = (value) => {
    if (value.length <= maxChars) {
      setNotes(value);
    } else {
      alert("You have exceeded the 250 character limit.");
    }
  };

  const updatePersonalInfo = async () => {
    try {
      const updatedInfo = {
        ...personalInfo,
        notes,
      };
      const response = await Api.put(`/update-info/${code}`, updatedInfo);
      alert(response.data.message);
    } catch (error) {
      console.error("Error updating personal info:", error);
    }
  };

  return (
    <div>
      <Typography variant="h4" color="White" mb={2} className="container mx-auto text-center" style={{ marginBottom: '1.5rem' }}>
        Update Your Info
      </Typography>
   
      <FormContainer>
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          value={personalInfo.name || ""}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          sx={{
            '& label': { color: 'white',  marginTop: '-8px'  },
            '& fieldset': { borderColor: 'white', borderRadius: '10px' },
            '& input': { color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '10px' },
            marginBottom: '1.5rem',
          }}
        />
        <TextField
          label="Email"
          name="email"
          value={personalInfo.email || ""}
          variant="outlined"
          sx={{
            '& label': { color: 'white',  marginTop: '-8px' },
            '& fieldset': { borderColor: 'white', borderRadius: '10px' },
            '& input': { color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '10px' },
            marginBottom: '1.5rem',
          }}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
        />
        <Typography variant="subtitle1"  color="White" mb={2} className="container mx-auto text-center" style={{ marginBottom: '-1rem' }}>
          Details:
        </Typography>
        <ReactQuill ref={quillRef} value={notes} onChange={handleNotesChange} />
        <Typography variant="caption" mb={2} color="White">
        {remainingChars} characters remaining
      </Typography>
        <Button variant="contained" color="primary" onClick={updatePersonalInfo}>
          Update Yuor Info
        </Button>
      </FormContainer>
    </div>
  );
}

export default UpdatePersonalInfo;
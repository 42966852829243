import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import Api from "./Api";
import { Button, TextField, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import QRCode from "qrcode.react";
import UserInfo from "./UserInfo";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import Layout from "./components/Layout";
import YouTube from "react-youtube"; 
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.css";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
});


function Welcome() {
  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1
    },
  };

  return (
		<div className="min-h-screen flex flex-col text-white">
			<main className="container mx-auto px-6 pt-16 flex-1 text-center">

				<h2 className="text-2xl md:text-4xl lg:text-6xl uppercase">Welcome to</h2>
				<h1 className="text-3xl md:text-6xl lg:text-8xl uppercase font-black mb-8">FunPup TAG App</h1>

				<p className="text-base md:text-lg lg:text-2xl mb-8">Secure your pet with FunPup Collar: <br/>
        Buy from our Etsy shop and receive a stylish collar with a custom QR code buckle. <br/>
        Update your pet's info seamlessly through our app, ensuring they're always a scan away from home. <br/>
        Get FunPup and embrace peace of mind.</p>

				<div className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6 lg:px-12 bg-white bg-opacity-10 w-fit mx-auto mb-8 rounded-full">
					943,245 members
				</div>

				<form 
					action="https://www.getrkevue.co/profile/tyler_potts_/add_subscriber" 
					method="post" 
					id="revue-form"
					name="revue-form"
					target="_blank">

					<div className="flex flex-col md:flex-row justify-center mb-4">
						<input
							placeholder="Your email address..."
							type="email"
							name="member[email]"
							id="member_email"
							className="text-2xl placeholder:text-gray-400 placeholder:italic py-4 px-6 md:px-10 lg:py-6 lg:px-12 bg-white bg-opacity-10 focus:bg-opacity-20 duration-150 md:rounded-tr-none md:rounded-br-none rounded-full outline-none mb-4 md:mb-0"
						/>
						<input
							type="submit"
							value="Join Today"
							name="member[subscribe]"
							id="member_submit"
							className="bg-primary md:rounded-tl-none md:rounded-bl-none rounded-full text-2xl py-4 px-6 md:px-10 lg:py-6 lg:px-12 font-bold uppercase cursor-pointer hover:opacity-75 duration-150"
						/>
					</div>

					<div className="opacity-75 italic">
						By subscribing, you agree with FunPup's <a target="_blank" href="https://www.getrsevue.co/terms" className="hover:opacity-80 duration-150">Terms of Service</a> and <a target="_blank" href="https://www.getrevue.co/privacy" className="hover:opacity-80 duration-150">Privacy Policy</a>.
					</div>

				</form>
			</main>

			<footer className="container mx-auto p-6">
				<div className="flex flex-col md:flex-row items-center justify-between">
					<p className="mb-4 md:mb-0 md:text-xl">Built with 💖 by Tyler Potts</p>

					<div className="flex -mx-6">
						<a href="#" className="mx-3 hover:opacity-80 duration-150">About us</a> | 
						<a href="#" className="mx-3 hover:opacity-80 duration-150">Privacy</a> | 
						<a href="#" className="mx-3 hover:opacity-80 duration-150">Contact</a>
					</div>
				</div>
			</footer>
		</div>	
	)
}

function CodeAndUserCreation() {
  const [personalInfo, setPersonalInfo] = useState({});
  const [userId, setUserId] = useState("");
  const [code, setCode] = useState("");

  const [notes, setNotes] = useState("");


  const handleNotesChange = (value) => {
    setNotes(value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const generateCode = async () => {
    try {
      const response = await Api.post("/generate-code");
      setCode(response.data.code);
      setUserId(response.data.userId);
    } catch (error) {
      console.error("Error generating code:", error);
    }
  };

;

  const savePersonalInfo = async () => {
    try {
      const infoWithNotes = { ...personalInfo, notes };
      const response = await Api.post(`/add-info/${userId}`, infoWithNotes);
      alert(response.data.message);
    } catch (error) {
      console.error("Error saving personal info:", error);
    }
  };


  return (
    <div className="min-h-screen flex flex-col text-white">
      <Typography variant="h4" mb={2} color="White">
        Generate Special Code
      </Typography>
      <FormContainer >
        <TextField 
          label="Name"
          name="name"
          variant="outlined" 
          InputLabelProps={{ style: { color: "white" } }}
          InputProps={{
            style: { color: "black", backgroundColor: "white", opacity: 0.7 },
          }}
          onChange={handleInputChange}
        />
        <TextField 
          label="Email"
          name="email"
          variant="outlined"
          InputLabelProps={{ style: { color: "white" } }}
          InputProps={{
            style: { color: "black", backgroundColor: "white", opacity: 0.7 },
          }}
          onChange={handleInputChange}
        />
        <ReactQuill value={notes} onChange={handleNotesChange} 
                  InputLabelProps={{ style: { color: "white" } }}
                  InputProps={{
                    style: { color: "black", backgroundColor: "white", opacity: 0.7 },
                  }}
        />
       <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={generateCode}
          onTouchStart={generateCode}
        >
          Generate Code
      </Button>
        {code && (
          <div>
            <Typography variant="h6">Your Special Code:</Typography>
            <Typography variant="h5" color="secondary">
              {code}
            </Typography>
            <Typography variant="h6">Scan Green QR Code to Access Info:</Typography>
            <Typography variant="body1">
              <a href={`${window.location.origin}/qr-code-data/${userId}`} target="_blank" rel="noopener noreferrer">
                {`${window.location.origin}/qr-code-data/${userId}`}
              </a>
            </Typography>
            <QRCode
              value={`${window.location.origin}/qr-code-data/${userId}`}
              fgColor="#4CAF50"
            />
            <Typography variant="h6" mt={2}>
              Scan Red QR Code to Update Info:
            </Typography>
            <Typography variant="body1">
              <a href={`${window.location.origin}/update-personal-info?code=${code}`} target="_blank" rel="noopener noreferrer">
                {`${window.location.origin}/update-personal-info?code=${code}`}
              </a>
            </Typography>
            <QRCode
              value={`${window.location.origin}/update-personal-info?code=${code}`}
              fgColor="#F44336"
            />
          </div>
        )}
        <Button variant="contained" color="primary" onClick={savePersonalInfo}>
          Save Personal Info
        </Button>
      </FormContainer>
    </div>
  );
}

function ProtectedCreate() {
  const [password, setPassword] = useState("");
  const hardcodedPassword = "myPassword";

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const checkPassword = () => {
    if (password === hardcodedPassword) {
      return <CodeAndUserCreation />;
    } else {
      return (
        <Box>
          <Typography variant="h6">Enter Password to Access:</Typography>
          <TextField
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            onChange={handlePasswordChange}
          />
          {password && (
            <Typography variant="body1" color="error">
              Incorrect password. Please try again.
            </Typography>
          )}
        </Box>
      );
    }
  };

  return checkPassword();
}


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/create" element={<ProtectedCreate />} />
          <Route path="/qr-code-data/:userId" element={<UserInfo />} />
          <Route path="/update-personal-info" element={<UpdatePersonalInfo />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
